// import {Auth} from 'aws-amplify';
import {Auth} from '@aws-amplify/auth';

export default {
	data() {
		return {
			popUpWindow: null,
			// polling: null,
		}
	},
	
	methods: {
		signUp(){
			this.error = null;
			this.loading = true;
			this.email = this.email.toLowerCase().trim();
			
			// if ( !this.hasAcceptedLegal ){
			// 	this.error = 'For å gå videre trenger vi din aksept på vilkårene';
			// 	console.log('hasAcceptedLegal is FALSE');
			// 	this.loading = false;
			// 	return;
			// }
			
			const data = {
				username: this.email,
				password: this.password,
				attributes: {
					email: this.email,
					// name: this.name,
					locale: 'nb',
				}
			};
			
			Auth.signUp(data)
			.then( user => {
				console.log('auth signup response');
				console.log(user);
				
				const trackEmail = this.email ? this.email : 'unknown email';
				const trackData = {
					email: this.email,
					user,
					text: '*signUp* by '+ trackEmail + "\n" + JSON.stringify(user, null, 4),
				};
				
				analytics.track('signUp', trackData);
				
				this.user = user;
				this.loading = false;
			})
			.catch( err => {
				console.log(err)
				this.error = err.message;
				this.loading = false;
			});
		},
		
		confirmSignUp(){
			this.error = null;
			this.loading = true;
			this.email = this.email.toLowerCase().trim();
			
			// After retrieving the confirmation code from the user
			Auth.confirmSignUp(this.email, this.confirmCode, {
				// Optional. Force user confirmation irrespective of existing alias. By default set to True.
				forceAliasCreation: true    
			})
			.then( data => {
				console.log('confirmSignUp response');
				console.log(data);
				
				this.signIn();
				this.loading = false;
				
				const trackEmail = this.email ? this.email : 'unknown email';
				const trackData = {
					email: this.email,
					data,
					text: '*confirmSignUp* by '+ trackEmail + "\n" + JSON.stringify(data, null, 4),
				};
				
				analytics.track('confirmSignUp', trackData);
			})
			.catch( err => {
				console.log(err)
				this.error = err.message;
				this.loading = false;
			});
		},
		
		resendSignUp(){
			this.error = null;
			this.loading = true;
			this.email = this.email.toLowerCase().trim();
			
			Auth.resendSignUp(this.email)
			.then(() => {
				this.loading = false;
				this.$notify({ type: 'success', text: 'The code was resent. Please check your email inbox' });
				
				const trackEmail = this.email ? this.email : 'unknown email';
				const trackData = {
					email: this.email,
					text: '*resendSignUp* by '+ trackEmail,
				};
				
				analytics.track('resendSignUp', trackData);
			})
			.catch( e => {
				this.$notify({ 
					type: 'error', 
					title: 'Error', 
					text: 'There was a problem while resending the confirmation code'
				});
				console.error(e);
				this.error = e.message;
				this.loading = false;
			});
		},
		
		async signIn() {
			try {
				this.error = null;
				this.loading = true;
				this.email = this.email.toLowerCase().trim();
				
				// if ( !this.hasAcceptedLegal ){
				// 	this.error = 'For å logge på din konto, trenger vi din aksept på vilkårene';
				// 	console.log('hasAcceptedLegal is FALSE');
				// 	this.loading = false;
				// 	return;
				// }
				
				const user = await Auth.signIn({
					username: this.email,
					password: this.password,
				});
				
				console.log('user', user);
				
				
				const signedInStatus = await this.checkUser();
				console.log('signedInStatus', signedInStatus);
				
				
				// if (user.attributes.zoneinfo) {
				//     console.log('user.attributes.zoneinfo');
				//     const departments = JSON.parse( user.attributes.zoneinfo );
				//     console.log(departments);
				// }
				
				// this.loading = false;
				
				if (signedInStatus) {
					// this.segmentTrack('Logged in', {
					//     category: 'Account',
					// });
					
					const trackEmail = this.email ? this.email : 'unknown email';
					const userAttributes = user && user.attributes ? user.attributes : null;
					
					const trackData = {
						email: this.email,
						text: '*signIn* by '+ trackEmail +"\n" + JSON.stringify(userAttributes, null, 4),
					};
					analytics.track('signIn', trackData);
					
					this.$router.push({name: 'accounts'});
				}
				else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
					// console.log('NEW_PASSWORD_REQUIRED');
					// console.log('user.challengeParam', user.challengeParam);
					
					const trackProperties = {
					    email: this.email,
					    challengeName: user.challengeName,
					};
					
					const userAttributes = user && user.attributes ? user.attributes : null;
					const trackEmail = this.email ? this.email : 'unknown email';
					const trackData = {
						email: this.email,
						text: '*signIn* by '+ trackEmail +"\n" + JSON.stringify(userAttributes, null, 4),
					};
					analytics.track('signIn', trackData);
					
					this.$router.push({ name: 'newpasswordrequired', params: { user } });
				}
				else {
					this.$router.push({ name: 'signup', params: { error: 'Could not log in after sign up.' } });
				}
			} 
			catch (err) {
				console.log(err);
				this.error = err.message;
				this.loading = false;
				
				const trackProperties = {
					email: this.email,
					error: err.message,
				};
			}
		},
		
		async checkUser(){
			try {
				const user = await Auth.currentAuthenticatedUser({
					bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
				});
				
				// console.log('checkUser', user);
				
				// this.$store.state.signedIn = true;
				// 
				this.$store.commit('setUser', user);
				// this.$store.commit('setAuthorizationHeader', jwt);
				// console.log('checkUser in auth', user);
				return user;
			}
			catch (err) {
				// console.log('checkUser error', err);
				// console.log('checkUser in auth NOPE');
				// this.$store.state.signedIn = false;
				// this.$store.state.user = null;
				this.$store.commit('setUser', null);
				// this.$store.commit('setAuthorizationHeader', null);
			}
		},
		
		signOut(){
			const trackEmail = this.email ? this.email : 'unknown email';
			const trackData = {
				email: this.email,
				text: '*signOut* by '+trackEmail, //+"\n" + JSON.stringify(account, null, 4),
			};
			analytics.track('signOut', trackData); // this.email);
			
			Auth.signOut()
			.then( data => {
		 		localStorage.removeItem('showAddToHomeScreen');
				this.$router.push({name: 'signin'});
			})
			.catch( err => {
				console.log(err);
				this.error = err.message;
			});
		},
		
		resetPasswordEmail(){
			this.error = null;
			this.loading = false;
			this.email = this.email.toLowerCase().trim();
			
			const trackEmail = this.email ? this.email : 'unknown email';
			const trackData = {
				email: this.email,
				text: '*resetPasswordEmail* by '+ trackEmail,
			};
			analytics.track('resetPasswordEmail', trackData);
			
			Auth.forgotPassword(this.email)
			.then(data => {
				console.log('data from forgotPassword', data);
				this.confirmInProgress = data;
			})
			.catch(err => {
				console.log(err);
				this.error = err.message;
			});
		},
		
		resetPasswordSubmit(){
			this.error = null;
			this.email = this.email.toLowerCase().trim();
			this.loading = false;
			
			const trackEmail = this.email ? this.email : 'unknown email';
			const trackData = {
				email: this.email,
				text: '*resetPasswordSubmit* by '+ trackEmail,
			};
			analytics.track('resetPasswordSubmit', trackData); // this.email );
			
			// Collect confirmation code and new password, then
			Auth.forgotPasswordSubmit(this.email, this.confirmCode, this.password)
			.then(data => {
				console.log('data from forgotPasswordSubmit', data);
				this.signIn();
			})
			.catch(err => {
				console.log(err);
				this.error = err.message;
				this.loading = false;
			});
		},
		
		newPasswordSubmit( userPassed, password ){
			this.error = null;
			this.loading = true;
			this.email = userPassed.challengeParam.userAttributes.email;
			
			Auth.completeNewPassword( userPassed, password)
			.then(user => {
				// at this time the user is logged in if no MFA required
				console.log(user);
				
				const trackEmail = this.email ? this.email : 'unknown email';
				const trackData = {
					user,
					text: '*newPasswordSubmit* by '+ trackEmail + "\n" + JSON.stringify(user, null, 4),
				};
				
				analytics.track('newPasswordSubmit', trackData); // this.email );
				
				
				this.signIn();
			})
			.catch(err => {
				console.log(err);
				this.error = err.message;
				this.loading = false;
			});
		},
	}
}
