<template>
    <div class="w-full h-full flex flex-col items-center md:justify-center bg-beige overflow-auto">
        <div 
            v-if="!user"
            class="flex flex-col md:flex-row m-8 md:rounded-lg md:overflow-hidden">
            
            <div class="w-full md:w-64 bg-gray-darker text-white p-8 pl-12 flex flex-col rounded-t-lg md:rounded-none">
                <div class="md:h-32">
                    <img 
                        src="/assets/logo.svg" 
                        class=" my-4"
                    />
                </div>
                
                <h2>
                    Opprett konto
                </h2>
                <p class="text-gray-lightest">
                    Skriv inn e-postadresse og passord for din konto. For å vite at du er eieren av epostadressen vil vi sende deg en engangskode.
                </p>
            </div>
            
            <div class="w-full md:w-auto flex-grow bg-white p-8 md:px-12 h-full flex flex-col rounded-b-lg md:rounded-none">
                <form @submit.prevent="signUp()">
                    <div v-if="error" class="bg-red-600 text-white p-4 mb-12 rounded text-center">
                        <!-- <h2 class="text-white">Error</h2> -->
                        {{error}}
                    </div>
                    
                    <!-- <InputSingleline 
                        v-model="name"
                        label="name"
                        name="name"
                        placeholder="name"
                        class="w-full -mt-4"
                        type="text"
                        :required="true"
                        :autofocus="true"
                    /> -->
                    
                    <InputSingleline 
                        v-model="email"
                        label="E-post"
                        name="email"
                        placeholder="john@example.com"
                        class="w-full -mt-4"
                        type="email"
                        :required="true"
                    />
                    
                    <InputSingleline 
                        v-model="password"
                        label="Passord"
                        name="password"
                        class="w-full"
                        type="password"
                        :required="true"
                    />
                    
					<div 
						v-if="showAcceptedLegal"
						class="mt-8 mb-4">
						
						<input 
							type="checkbox" 
							v-model="hasAcceptedLegal"
						/>
						Jeg har lest og aksepterer <a class="link" @click.stop="showLegalDocumentNavigation = !showLegalDocumentNavigation">vilkårene for bruk av Upsell</a>
					</div>
					
					<div 
						v-if="showLegalDocumentNavigation"
						class="py-2">
						
						<ul class="space-y-2">
							<li>
								<a 
									href="https://docs.google.com/document/d/1DCfx9KNvvEdrWbN1fLAtKNXJc1ZHbnDTJVEpA7ZeKxE/edit?usp=sharing"
									target="_blank"
									class="link">
									
									UpSell Terms and Conditions
								</a>
							</li>
							<li>
								<a 
									href="https://docs.google.com/document/d/1P3uRxaxbIx7FA5o2jj8VjAY8cmlMWn5ozwTTomcSjJQ/edit?usp=sharing"
									target="_blank"
									class="link">
									
									UpSell Customer Agreement
								</a>
							</li>
							<li>
								<a 
									href="https://docs.google.com/document/d/1WadRhTcMlsIq8Kp1F14EqZDLavSuMIzuZOBgoXfiKsE/edit?usp=share_link"
									target="_blank"
									class="link">
									
									The legal aspects of electronic signatures
								</a>
							</li>
						</ul>
					</div>
					
                    <button 
                        type="submit" 
                        class="button submit my-4 w-full" 
                        :class="{'loading' : loading}"
                        :disabled="loading">
                        
                        Registrer deg
                    </button>
                </form>
                
                <div class="text-center flex flex-col">
                    <router-link :to="{ name: 'signin'}">
                        Registrert som bruker allerede?
                    </router-link>
                </div>
            </div>
        </div>
        
        <div 
            v-else-if="user"
            class="flex flex-col md:flex-row m-8 md:rounded-lg md:overflow-hidden">
            
            <div class="w-full md:w-64 bg-gray-darker text-white p-8 pl-12 flex flex-col rounded-t-lg md:rounded-none">
                <div class="md:h-32">
                    <img 
                        src="/assets/logo.svg" 
                        class=" my-4"
                    />
                </div>
                
                <h2>
                    <!-- confirmEmail -->
                    Bekreft e-post
                </h2>
                <p class="text-gray-lightest">
                    <!-- confirmEmailText -->
                    For å bekrefte at du er eieren av denne e-postadressen, har vi sendt deg en e-post med en bekreftelseskode
                </p>
            </div>
            <div class="w-full md:w-auto flex-grow bg-white p-8 md:px-12 h-full flex flex-col rounded-b-lg md:rounded-none">
                <form @submit.prevent="confirmSignUp()">
                    <div v-if="error" class="bg-red-600 text-white p-4 mb-12 rounded text-center">
                        <!-- <h2 class="text-white">Error</h2> -->
                        {{error}}
                    </div>
                    
                    <!-- <p>
                        {{email}}
                    </p> -->
                    
                    <!-- <InputSingleline 
                        v-model="email"
                        label="email"
                        name="email"
                        placeholder="email"
                        class="w-full -mt-4"
                        :required="true"
                    /> -->
                    
                    <InputSingleline 
                        v-model="confirmCode"
                        label="Bekreftelseskode"
                        name="confirmCode"
                        class="w-full"
                        :required="true"
                        :autofocus="true"
                    />
                    
                    <button 
                        type="submit" 
                        class="button submit my-4 w-full" 
                        :class="{'loading' : loading}"
                        :disabled="loading">
                        
                        Bekreft
                    </button>
                </form>
                
                <div class="text-center flex flex-col">
                    <a 
                        class="cursor-pointer" 
                        @click="resendSignUp()">
                        
                        Send bekreftelseskode på nytt
                    </a>
                </div>
            </div>
        </div>
        
        <router-link :to="{ name: 'www'}">
            <!-- backToWebsite -->
            Tilbake til websidene
        </router-link>
    </div>
</template>

<script>

import AuthMixins from '@/mixins/AuthMixins';

export default {
    data() {
        return {
            // name: null,
            email: null,
            password: null,
			hasAcceptedLegal: false,
			showAcceptedLegal: true,
			showLegalDocumentNavigation: false,
            error: null,
            user: null,
            confirmCode: null,
            loading: false,
        }
    },
    
    mixins: [
        AuthMixins,
    ],
    
	watch: {
		hasAcceptedLegal( newVal ){
			localStorage.hasAcceptedLegal = JSON.stringify( newVal );
		},
	},
	
    mounted(){
        if (
            this.$route && 
            this.$route.query && 
            this.$route.query.promoCode || this.$route.query.promocode ) {
            
            localStorage.promoCode = this.$route.query.promoCode || this.$route.query.promocode;
        }
		
		if ( !localStorage.hasAcceptedLegal ) {
			this.showAcceptedLegal = true;
		}
        // https://localhost:8080/signup?promoCode=sellit2021
    }
}
</script>
